import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useAvtaleGiroRedirect = () => {
  const location = useLocation();

  useEffect(() => {
    const avtaleGiroData = localStorage.getItem('avtalegiro');

    if (avtaleGiroData) {
      const { magicToken, applicationId, jwt } = JSON.parse(
        avtaleGiroData || '{}'
      );

      const searchParams = new URLSearchParams(location.search);

      if (magicToken) {
        searchParams.set('id', magicToken);
      }

      if (applicationId) {
        searchParams.set('id', applicationId);
      }

      localStorage.removeItem('avtalegiro');

      window.location.replace(
        `${location.pathname}?${searchParams.toString()}${
          jwt ? `#token=${jwt}` : ''
        }`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
