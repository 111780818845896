import './config/sentry.config';

import {
  getLDConfigWithAnonymousUser,
  ProductLDContext,
} from '@axo/services/launch-darkly';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { TranslationProvider } from '@axo/mypage/util/translation';
import { Languages } from '@axo/shared/util/translation';
import { ToastProvider } from '@axo/shared/feature/providers';
import { LocaleProvider } from '@axo/mypage/feature';
import { getMixpanelConfig } from '@axo/shared/services/analytics/mixpanel/config/getMixpanelConfig';
import { MarketCountryCode } from '@axo/shared/types';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import { AnalyticsProvider } from '@axo/shared/services/analytics';
import mixpanel from 'mixpanel-browser';

const root = ReactDOM.createRoot(
  document.getElementById('my-page') as HTMLElement
);

const ldConfig = getLDConfigWithAnonymousUser({
  clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID as string,
  context: {
    mypage: {
      kind: 'mypage',
      name: 'no',
      key: 'mypage-no',
    } as ProductLDContext,
  },
  isProduction: !import.meta.env.DEV,
});

!import.meta.env.CI &&
  mixpanel.init(
    import.meta.env.VITE_MIXPANEL_TOKEN as string,
    getMixpanelConfig(import.meta.env.DEV)
  );

const AppRoot = withLDProvider(ldConfig)(() => {
  const ldClient = useLDClient();
  return (
    <AnalyticsProvider
      enabledServices={[
        'mixpanel',
        { service: 'launchDarkly', params: { client: ldClient } },
        'clarity',
      ]}
    >
      <BrowserRouter>
        <LocaleProvider
          marketCountry={MarketCountryCode.Norway}
          locale="nb-NO"
          currency="kr"
          trustPilot={{
            businessId: '5476fe0400006400057bcaf1',
            templateId: '53aa8807dec7e10d38f59f32',
            link: 'https://no.trustpilot.com/review/axofinans.no',
          }}
        >
          <TranslationProvider language={Languages.nb} showDebugErrors={true}>
            <ToastProvider>
              <App />
            </ToastProvider>
          </TranslationProvider>
        </LocaleProvider>
      </BrowserRouter>
    </AnalyticsProvider>
  );
});

root.render(
  <React.StrictMode>
    <AppRoot />
  </React.StrictMode>
);
