interface CustomerKeyParams {
  customerID?: string | null;
}

export const customerKeys = {
  root: (params: CustomerKeyParams) => ['customer', params.customerID] as const,
  consent: (params: CustomerKeyParams) =>
    [...customerKeys.root(params), 'consent'] as const,
  events: (params: CustomerKeyParams) =>
    [...customerKeys.root(params), 'customer events'] as const,
  loanApplications: (params: CustomerKeyParams) =>
    [...customerKeys.root(params), 'loan applications'] as const,
};

export function parseCustomerKey<T extends keyof typeof customerKeys>(
  queryKey: ReturnType<(typeof customerKeys)[T]>
) {
  const [, customerID] = queryKey;

  return { customerID };
}
