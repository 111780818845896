import classNames from '../../../utils/class-names';
import { H2 } from '../../atoms/StyledElements';
import styles from './FAQ.module.scss';
import { IFAQ, IQuestion } from './FAQ.types';

const Icon = () => (
  <>
    <svg className={styles.plus} viewBox="0 0 460 460">
      <path d="M459 230c0 22-18 40-40 40H270v149a40 40 0 1 1-81 0V270H40a40 40 0 0 1-40-40c0-23 18-41 40-41h149V40a40 40 0 0 1 81 0v149h149c22 0 40 18 40 41z" />
    </svg>
    <svg className={styles.minus} viewBox="0 0 460 460">
      <path d="M459 230c0 22-18 40-40 40H40a40 40 0 0 1-40-40c0-23 18-41 40-41h379c22 0 40 18 40 41z" />
    </svg>
  </>
);

const Detail = ({ question, answer, ...rest }: IQuestion) => (
  <li>
    <details {...rest}>
      <summary>
        {question}
        <Icon />
      </summary>
      <p>{answer}</p>
    </details>
  </li>
);

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const FAQ = ({ _id, heading, faq: faqs, classes }: IFAQ) => (
  <section className={classNames(styles.faq, classes?.root)} key={_id}>
    <H2>{heading}</H2>
    <ul>
      {faqs.map((faq, index) => (
        <Detail key={faq._id + '_' + index} {...faq} />
      ))}
    </ul>
  </section>
);

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default FAQ;
