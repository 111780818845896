import { MyPageFlags, useCustomerAnalytics } from '@axo/mypage/util';
import { useTranslation } from '@axo/mypage/util/translation';
import { loan_quote_presentation } from '@axo/shared/data-access/types';
import { useFlags } from '@axo/shared/services/feature-flags';
import { TState } from '@axo/ui-feature/components/ProgressBarWithDescription/ProgressBarWithDescription';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  ApplicationProgressContext,
  initialProgressState,
} from '../../ApplicationProgressProvider';

export const useProgressBarWithDescription = ({
  quotes,
}: {
  quotes: loan_quote_presentation.GetQuotesResponse | undefined;
}) => {
  const { state, dispatch } = useContext(ApplicationProgressContext);
  const { t } = useTranslation();
  const {
    'unlock-offers-on-progress-update': flag_unlock_offers_on_progress_update,
  } = useFlags(MyPageFlags);
  const { trackOfferProgress } = useCustomerAnalytics();

  const [offersUnlocked, setOffersUnlocked] = useState(false);

  // Set offers state when updated Steps data received
  useEffect(() => {
    const offersResponse = (quotes?.Steps || []).reduce(
      (acc, step) => {
        acc.total += step.Total;
        acc.completed += step.Completed;
        return acc;
      },
      { total: 0, completed: 0 }
    );

    dispatch({
      type: 'Set offers',
      payload: offersResponse,
    });
  }, []);

  const allBanksResponded =
    (!!state.offers.total && state.offers.total === state.offers.completed) ||
    quotes?.AllRegisteredLendersResponded;
  const currentStep =
    (quotes?.Steps && quotes.Steps.find((step) => step.Step === 0)
      ? quotes.CurrentStep + 1
      : quotes?.CurrentStep) ?? 0;
  const isUnlockStep =
    currentStep >= (flag_unlock_offers_on_progress_update || 2);
  const hasOffers =
    quotes?.AcceptableLoanQuotes.length || quotes?.AcceptedLoanQuotes.length;

  useEffect(() => {
    const getProgressTitle = () => {
      if (allBanksResponded) return t('All banks have responded');
      if (isUnlockStep) {
        return hasOffers
          ? t('You have received at least one good offer')
          : t('Waiting for your first offer');
      }
      return hasOffers
        ? t('Awaiting response from banks')
        : initialProgressState.title;
    };

    const getProgressDescription = () => {
      if (allBanksResponded) {
        return hasOffers
          ? t('You can now select the offer you wish to proceed with')
          : t('Unfortunately, none of our bank partners have made an offer');
      }
      if (isUnlockStep) {
        return hasOffers
          ? t('Most banks have responded and you can now choose an offer')
          : t('The banks are currently processing your application');
      }
      return hasOffers
        ? t(
            'We recommend that you wait until all the banks have responded before you choose an offer'
          )
        : t('Please wait for more offers before making a choice');
    };

    const getProgressState = (): TState => {
      if (
        (allBanksResponded || isUnlockStep) &&
        (hasOffers || quotes?.CancelledLoanQuotes)
      ) {
        setOffersUnlocked(true);
        return 'success';
      }
      if (hasOffers) return 'warn';
      return initialProgressState.state;
    };

    const getProgressAmount = () => {
      if (allBanksResponded) return 100;
      if (state.offers.total > 0) {
        return Math.round((state.offers.completed / state.offers.total) * 100);
      }
      return initialProgressState.progress;
    };

    dispatch({
      type: 'Set progress',
      payload: {
        title: getProgressTitle(),
        description: getProgressDescription(),
        state: getProgressState(),
        progress: getProgressAmount(),
      },
    });
  }, [dispatch, flag_unlock_offers_on_progress_update, quotes, state.offers]);

  const hasInitialOfferRef = useRef(false);
  const hasOffersUnlockedRef = useRef(false);
  const amountOfOffersRef = useRef(0);
  useEffect(() => {
    const isInProgress = quotes?.StepsInitiated && !quotes?.StepsCompleted;
    const amountOfOffers = hasOffers ?? 0;

    if (allBanksResponded) {
      const event =
        amountOfOffers === 0
          ? 'My Page Offer None Notified'
          : 'My Page Offer All Notified';
      trackOfferProgress({
        event,
        progress: {
          amountOfOffers,
          currentStep,
        },
      });
    }

    if (!isInProgress) return;

    if (offersUnlocked && !hasOffersUnlockedRef.current) {
      trackOfferProgress({
        event: 'My Page Offer Selection Unlocked',
        progress: {
          amountOfOffers,
          currentStep,
        },
      });
      hasOffersUnlockedRef.current = true;
    }

    if (amountOfOffers === 1 && !hasInitialOfferRef.current) {
      trackOfferProgress({
        event: 'My Page Offer First Notified',
        progress: {
          amountOfOffers,
          currentStep,
        },
      });
      hasInitialOfferRef.current = true;
    }

    if (amountOfOffers > amountOfOffersRef.current) {
      trackOfferProgress({
        event: 'My Page Offer Notified',
        progress: {
          amountOfOffers,
          currentStep,
        },
      });
      amountOfOffersRef.current = amountOfOffers;
    }
  }, [quotes, state.offers, offersUnlocked]);

  return { offersUnlocked };
};
