import { Params } from '@axo/shared/util/translation';

export const translationsObj = {
  //general
  'Go back': () => {
    return {
      en: 'Go back',
      fi: 'Takaisin',
      nb: 'Tilbake',
    };
  },
  'Something went wrong': () => {
    return {
      en: 'Something went wrong',
      fi: 'Jotain meni pieleen',
      nb: (
        <>
          Det har oppstått en feil. Ta kontakt med kundeservice på{' '}
          <a href="tel:22867400">22 86 74 00</a>.
        </>
      ),
    };
  },
  'My Page': () => {
    return {
      en: 'My Page',
      fi: 'Omat sivut',
      nb: 'Min side',
    };
  },
  'Axo Finance: My Page': () => {
    return {
      en: 'Axo Finance: My Page',
      fi: 'Axo Finance: Omat sivut',
      nb: 'Axo Finance: Min side',
    };
  },
  '01234 56789': () => {
    return {
      en: '01234 56789',
      fi: '09 4245 2326',
      nb: '22 86 74 00',
    };
  },
  'customerservice@axofinance.com': () => {
    return {
      en: 'customerservice@axofinance.com',
      fi: 'asiakaspalvelu@axolaina.fi',
      nb: 'kundeservice@axofinans.no',
    };
  },
  'Redirecting...': () => {
    return {
      en: 'Redirecting...',
      fi: 'Uudelleenohjataan...',
      nb: 'Omdirigerer...',
    };
  },
  'Proceed to bank': () => {
    return {
      en: 'Proceed to bank',
      fi: 'Jatka pankin sivuille',
      nb: 'Fortsett til banken',
    };
  },
  Next: () => {
    return {
      en: 'Next',
      fi: 'Eteenpäin',
      nb: 'Neste',
    };
  },
  VAT: () => {
    return {
      en: 'VAT: 486578',
      fi: 'Y-tunnus: 2984343-1',
      nb: 'Organisasjonsnummer: 998543320',
    };
  },
  '© Axo Finance': () => {
    return {
      en: 'Axo Finance',
      fi: '© Axo Finance Oy',
      nb: '© Axo Finans AS',
    };
  },
  'Need help?': () => {
    return {
      en: 'Need help?',
      fi: 'Tarvitsetko apua?',
      nb: 'Trenger du hjelp?',
    };
  },
  'Choosing a loan can be difficult, but we are here for you': () => {
    return {
      en: "Choosing a loan can be difficult, but we are here for you. Call or email us and we'll help you.",
      fi: 'Lainan valinta voi olla vaikeaa, mutta olemme täällä sinua varten. Soita tai lähetä sähköpostia, niin autamme sinua.',
      nb: 'Å velge et lån kan være vanskelig, men vi er her for deg. Ring eller send oss en e-post, så hjelper vi deg.',
    };
  },
  'Loan Quotes': () => {
    return {
      en: 'Loan Quotes',
      fi: 'Lainatarjoukset',
    };
  },
  Insurance: () => {
    return {
      en: 'Insurance',
      fi: 'Vakuutus',
    };
  },
  'Insurance information': () => {
    return {
      en: 'Insurance information',
      fi: 'Vakuutuksen tiedot',
    };
  },
  'Credit card details': () => {
    return {
      en: 'Credit card details',
      fi: 'Maksukortin tiedot',
    };
  },
  'Policy ID': () => {
    return {
      en: 'Policy ID',
      fi: 'Vakuutusnumero',
    };
  },
  'Monthly coverage': () => {
    return {
      en: 'Monthly coverage',
      fi: 'Kuukausittainen kattavuus',
    };
  },
  'Monthly payment': () => {
    return {
      en: 'Monthly payment',
      fi: 'Kuukausittainen maksu',
    };
  },
  Status: () => {
    return {
      en: 'Status',
      fi: 'Tila',
    };
  },
  active: () => {
    return {
      en: 'active',
      fi: 'Aktiivinen',
    };
  },
  terminated: () => {
    return {
      en: 'terminated',
      fi: 'Irtisanottu',
    };
  },
  'Payment card number': () => {
    return {
      en: 'Payment card number',
      fi: 'Maksukortin numero',
    };
  },
  'Expiry date': () => {
    return {
      en: 'Expiry date',
      fi: 'Vanhenemispäivämäärä',
    };
  },
  'Downloadable information': () => {
    return {
      en: 'Downloadable information',
      fi: 'Ladattavat asiakirjat',
    };
  },
  'Edit payment method': () => {
    return {
      en: 'Edit payment method',
      fi: 'Muokkaa maksutapaa',
    };
  },
  'Resign insurance': () => {
    return {
      en: 'Resign insurance',
      fi: 'Irtisano vakuutus',
    };
  },
  'Insurance overview': () => {
    return {
      en: 'Insurance overview',
      fi: 'Yhteenveto vakuutuksesta',
    };
  },
  'Product Fact Sheet': () => {
    return {
      en: 'Product Fact Sheet',
      fi: 'Vakuutustietoasiakirja',
    };
  },
  'Pre-purchase Information': () => {
    return {
      en: 'Pre-purchase Information',
      fi: 'Etämyynnin ennakkotiedot',
    };
  },
  "You don't have an insurance": () => {
    return {
      en: "You don't have an insurance",
      fi: 'Sinulla ei ole voimassa olevaa vakuutusta',
    };
  },
  'Learn more about insurance': () => {
    return {
      en: 'Learn more about insurance',
      fi: 'Lue lisää vakuutuksesta',
    };
  },
  Contact: () => {
    return {
      en: 'Contact',
      fi: 'Ota yhteyttä',
      nb: 'Kontakt',
    };
  },
  'Log out': () => {
    return {
      en: 'Log out',
      fi: 'Kirjaudu ulos',
      nb: 'Logg ut',
    };
  },
  'Log in': () => ({
    en: 'Log in',
    fi: 'Kirjaudu sisään',
    nb: 'Logg inn',
  }),
  Yes: () => {
    return {
      en: 'Yes',
      nb: 'Ja',
    };
  },
  No: () => {
    return {
      en: 'No',
      nb: 'Nei',
    };
  },

  //homepage
  'Welcome!': () => {
    return {
      en: 'Welcome!',
      fi: 'Tervetuloa!',
      nb: (
        <>
          Velkommen til{' '}
          <span style={{ color: 'var(--primary-500)' }}>dine</span> tilbud!
        </>
      ),
    };
  },
  'Loading your offers': () => {
    return {
      en: 'Loading your offers...',
      fi: 'Ladataan tarjouksia...',
      nb: 'Laster tilbudene dine...',
    };
  },
  'As soon as you receive more offers': () => {
    return {
      en: 'As soon as you receive more offers we will notify you via email and sms and you will be able to preview them below.',
      fi: 'Saatuamme kaikilta yhteistyökumppaneiltamme vastauksen hakemukseesi, saat meiltä heti viestin ja pääset vertailemaan saamiasi tarjouksia.',
      nb: 'Vi venter fremdeles på svar fra noen banker og vi sender deg en SMS straks søknaden er ferdig behandlet.',
    };
  },
  'Your application ID is': () => {
    return {
      en: 'Your application ID is',
      fi: 'Hakemusnumerosi on',
      nb: 'Søknads-ID-en din er',
    };
  },
  'Change my selection': () => {
    return {
      en: 'Change my selection',
      fi: 'Katso kaikki tarjoukset',
      nb: 'Endre mitt valg',
    };
  },

  //approval indicator
  'All our bank partners have processed your application. You received': () => {
    return {
      en: 'All our bank partners have processed your application. You received',
      fi: 'Kaikki lainanantajat ovat käsitelleet hakemuksesi. Sait yhteensä',
      nb: 'Alle våre bankpartnere har behandlet søknaden din. Du har mottatt',
    };
  },
  offers: () => {
    return {
      en: 'offers',
      fi: 'tarjousta',
      nb: 'tilbud',
    };
  },
  offer: () => {
    return {
      en: 'offer',
      fi: 'tarjouksen',
      nb: 'tilbud',
    };
  },

  //statuses
  'Loading your offers, please wait': () => {
    return {
      en: 'Loading your offers, please wait',
      fi: 'Tarjouksia ladataan, odotathan rauhassa',
      nb: 'Laster tilbudene dine, vennligst vent',
    };
  },
  'Complete your application to receive a loan offer': (params: Params) => {
    return {
      en: (
        <>
          Complete your application to receive a loan offer.{' '}
          <a href={String(params[0])}>Click here</a>
        </>
      ),
      fi: (
        <>
          Täytä hakemus saadaksesi lainatarjouksen.{' '}
          <a href={String(params[0])}>Klikkaa tästä</a>
        </>
      ),
      nb: (
        <>
          Fullfør søknaden din for å motta lånetilbud.{' '}
          <a href={String(params[0])}>Trykk her</a>
        </>
      ),
    };
  },
  'Your application must be completed': () => {
    return {
      en: "Your application must be completed before we can register it with the banks. Click Contact at the top of the page and we'll help you.",
      fi: 'Hakemuksesi on täytettävä, ennen kuin voimme rekisteröidä sen pankeille. Napsauta sivun yläreunassa olevaa Ota yhteyttä, niin autamme sinua.',
      nb: 'Søknaden din må fullføres før vi kan registrere den hos bankene. Trykk på Kontakt øverst på siden, så hjelper vi deg.',
    };
  },
  'No active loan application': (params?: Params) => {
    const link = params && String(params[0]);
    return link
      ? {
          en: (
            <>
              You have no active loan application. <a href={link}>Click here</a>{' '}
              to access the application form.
            </>
          ),
          fi: (
            <>
              Sinulla ei ole aktiivista lainahakemusta.{' '}
              <a href={link}>Klikkaa tästä</a> päästäksesi hakemuslomakkeeseen.
            </>
          ),
          nb: (
            <>
              Du har ingen aktiv søknad. <a href={link}>Trykk her</a> for å
              komme til søknadsskjemaet.
            </>
          ),
        }
      : {
          en: 'You have no active loan application.',
          fi: 'Sinulla ei ole aktiivista lainahakemusta.',
          nb: 'Du har ingen aktiv søknad.',
        };
  },
  'We have received your loan application and registering it': () => {
    return {
      en: 'We have received your loan application and are in the process of registering it with the banks.',
      fi: 'Olemme vastaanottaneet lainahakemuksesi ja olemme parhaillaan rekisteröimässä sitä pankkeihin.',
      nb: 'Vi har mottatt din søknad, og jobber med å registrere den til bankene.',
    };
  },
  'You already have an active loan application with us': () => {
    return {
      en: 'You already have an active loan application with us. Click Contact at the top of the page and we will help you.',
      fi: 'Sinulla on jo aktiivinen lainahakemus meillä. Napsauta sivun yläreunassa olevaa Ota yhteyttä, niin autamme sinua.',
      nb: 'Du har allerede en aktiv søknad hos oss. Trykk på Kontakt øverst på siden, så hjelper vi deg.',
    };
  },
  'You have made your selection and are awaiting for the loan to be paid out.':
    () => {
      return {
        en: 'You have made your selection and are awaiting for the loan to be paid out.',
        fi: 'Lainatarjous on hyväksytty ja odottaa allekirjoitustasi.',
        nb: (
          <>
            Ta kontakt med kundeservice på{' '}
            <a href="tel:22867400">22 86 74 00</a> eller på chat dersom du
            ønsker å endre valgt lån.
          </>
        ),
      };
    },
  'Waiting for any bank to respond': () => {
    return {
      en: 'Waiting for any bank to respond.',
      fi: (
        <>
          Hakemuksesi on lähetetty kaikille lainanantajillemme. <br /> Otamme
          sinuun yhteyttä tekstiviestillä ja sähköpostilla, kun saat tarjouksen.
        </>
      ),
      nb: (
        <>
          Søknaden din er sendt til alle våre banker. <br /> Vi kontakter deg på
          SMS og e-post når du mottar tilbud.
        </>
      ),
    };
  },
  'Loan offer accepted by Axo': () => {
    return {
      en: 'Offer accepted by Axo',
      fi: 'Lainatarjous on valittu ja suoritat lainan maksun pankin verkkosivuilla. Jos haluat muuttaa lainatarjousta, voit klikata yllä olevaa Ota yhteyttä -valikkoa, niin autamme sinua.',
      nb: 'Lånetilbud er valgt og du fullfører utbetalingen av lånet på banken sine hjemmesider. Dersom du ønsker å endre lånetilbud kan du trykke på Kontakt i menyen over så skal vi hjelpe deg.',
    };
  },
  'Credit card offer accepted by Axo': () => {
    return {
      en: 'Offer accepted by Axo',
      nb: 'Kredittkorttilbud er valgt og du fullfører søknaden på banken sine hjemmesider. Dersom du ønsker å endre tilbud kan du trykke på Kontakt i menyen over så skal vi hjelpe deg.',
    };
  },
  'You have selected an offer': () => {
    return {
      en: 'You have selected an offer',
      fi: 'Olet valinnut lainatarjouksen. Suorita allekirjoitus ja maksaminen loppuun jatkamalla pankin verkkosivuilla. Jos haluat muuttaa lainatarjousta, voit klikata yllä olevaa Ota yhteyttä -valikkoa, niin autamme sinua.',
      nb: 'Du har valgt lånetilbud. For å fullføre signering og utbetaling fortsetter du på banken sine hjemmesider. Dersom du ønsker å endre lånetilbud kan du trykke på Kontakt i menyen over så skal vi hjelpe deg.',
    };
  },
  'You have selected a credit card': () => {
    return {
      en: 'You have selected a credit card',
      nb: 'Du har valgt kredittkortet under. Fortsett videre for å signere avtalen med BankID.',
    };
  },
  'You received your first offer!': () => {
    return {
      en: 'You received your first offer! Please wait for all lenders to respond, to make sure you get the best offer.',
      fi: 'Olet saanut ensimmäisen lainatarjouksen! Odotamme vielä lisää tarjouksia.',
      nb: 'Du har mottatt ditt første tilbud! Vent på at alle långivere svarer for å sikre at du får det beste tilbudet.',
    };
  },
  'Please wait for all lenders to respond': () => {
    return {
      en: 'Please wait for all lenders to respond, to make sure you get the best offer.',
      fi: 'Odotamme lainatarjouksia kumppaneiltamme.',
      nb: 'Vent til alle långivere har svart for å sikre at du får det beste tilbudet.',
    };
  },
  'All of our bank partners have responded': () => {
    return {
      en: 'All of our bank partners have responded. Please make your selection below.',
      fi: 'Kaikki lainanantajat ovat vastanneet. Tutustu lainatarjouksiin alta.',
      nb: 'Din søknad er ferdig behandlet.',
    };
  },
  'Unfortunately, your offer has been cancelled.': () => {
    return {
      en: 'Unfortunately, your offer has been cancelled.',
      fi: 'Valitettavasti lainatarjouksesi on peruttu.',
      nb: 'Tilbudet som ble valgt er ikke lenger gyldig. Det kan være fordi du har valgt å takke nei, eller for at banken har gitt avslag etter kontroll av dokumentene. Velg et nytt tilbud fra listen under.',
    };
  },
  'Unfortunately, your offer has been cancelled. No other offers available.':
    () => {
      return {
        en: 'Unfortunately, your offer has been cancelled. No other offers available.',
        fi: 'Valitsemasi tarjous ei ole enää voimassa. Ota meihin yhteyttä painamalla sivun yläreunassa olevaa Ota yhteyttä -linkkiä, niin autamme sinua.',
        nb: 'Tilbudet som ble valgt er ikke lenger gyldig. Det kan være fordi du har valgt å takke nei, eller for at banken har gitt avslag etter kontroll av dokumentene. Trykk på Kontakt på toppen av siden dersom du ønsker å komme i kontakt med oss.',
      };
    },
  'We have closed the application for a credit card.': () => {
    return {
      en: 'We have closed the application for a credit card.',
      nb: 'Vi har avsluttet søknaden om kredittkort. Dette kan være fordi banken har gitt avslag etter at de har kontrollert dokumenter, eller at du har valgt å takke nei.',
    };
  },
  'Your loan has been disbursed and will appear in your account in a few days.':
    () => {
      return {
        en: 'Your loan has been disbursed and will appear in your account in a few days.',
        fi: 'Lainasi on lähtenyt maksuun (ja näkyy tililläsi muutaman päivän sisällä)',
        nb: (
          <>
            Lånet ditt er utbetalt. Ring kundeservice på{' '}
            <a href="tel:22867400">22 86 74 00</a> hvis du har spørsmål.
          </>
        ),
      };
    },
  'Your credit card has been sent from the bank and you will receive it within a few days':
    () => {
      return {
        en: 'Your credit card has been sent from the bank and you will receive it within a few days',
        nb: 'Kredittkortet ditt er sendt fra banken, og du vil motta det i løpet av noen få dager',
      };
    },
  'You have no active applications': () => {
    return {
      en: 'You have no active applications.',
      nb: 'Du har ingen aktiv søknad nå. Send oss en søknad, så skal vi innhente tilbud fra bankene vi samarbeider med.',
    };
  },

  //no offers
  'Thank you for you application!': () => {
    return {
      en: 'Thank you for you application!',
      fi: 'Kiitos lainahakemuksestasi!',
      nb: 'Takk for søknaden din!',
    };
  },
  'Our lenders have processed your application, but no offer': () => {
    // TODO: Legacy. Duplicated in HeaderInfo. Remove if no need.
    return {
      en: 'Our lenders have processed you application, but unfortunately, did not return an offer.',
      fi: 'Kaikki yhteistyökumppanimme ovat käsitelleet hakemuksesi, mutta valitettavasti et saanut yhtäkään tarjousta.',
      nb: 'Bankene har behandlet søknaden din, men dessverre ikke kommet med noen tilbud.',
    };
  },
  'All our partners have now processed your loan application and unfortunately the offers have been declined.':
    () => {
      return {
        en: 'All our partners have now processed your loan application and unfortunately the offers have been declined.',
        fi: 'Kaikki yhteistyökumppanimme ovat nyt käsitelleet lainahakemuksesi ja valitettavasti lainapäätös on kielteinen.',
        nb: 'Alle våre partnere har nå behandlet søknaden din, og dessverre har tilbudene blitt avslått.',
      };
    },
  "We'll work on your application to see if we can find a deal for you.":
    () => {
      return {
        en: "We'll work on your application to see if we can find a deal for you.",
        nb: 'Vi jobber med søknaden din for å se om vi kan finne tilbud til deg.',
      };
    },
  'The selected offer has been finalized by the bank. We are working on your application to see if we can find other offers for you.':
    () => {
      return {
        en: 'The selected offer has been finalized by the bank. We are working on your application to see if we can find other offers for you.',
        nb: 'Det valgte tilbudet er avsluttet av banken. Vi jobber med søknaden din for å se om vi kan finne andre tilbud til deg.',
      };
    },
  'Please consult the guides below on how to improve your credit score': () => {
    return {
      en: 'Please consult the guides bellow on how to improve your credit score and maximizing your chances of getting a loan',
      fi: 'Katso, kuinka pankit määrittävät lainapotentiaalisi ja ota taloutesi haltuun. Tarkista luottopisteesi ilmaiseksi!',
      nb: 'Vennligst se guidene nedenfor for å forbedre kredittscoren din og maksimere sjansene dine for å få et lån',
    };
  },
  "Discover your credit score for free and in less than 2 minutes - don't wait any longer to take control of your finances.":
    () => {
      return {
        en: "Discover your credit score for free and in less than 2 minutes - don't wait any longer to take control of your finances.",
        fi: 'Selvitä luottopisteesi ilmaiseksi vain kahdessa minuutissa - ja ota taloutesi haltuun jo tänään.',
        nb: 'Oppdag din kredittscore gratis på under to minutter - vent ikke lenger med å ta kontroll over økonomien din.',
      };
    },
  'Loan application guide': () => {
    return {
      en: 'Loan application guide',
      fi: 'Syitä lainahakemuksen hylkäykseen',
      nb: 'Guide for søknad',
    };
  },
  'Check credit score': () => {
    return {
      en: 'Check credit score',
      fi: 'Tarkista luottopisteesi',
      nb: 'Sjekk kredittscoren din',
    };
  },

  // HeaderInfo
  'Complete your application': () => ({
    en: 'Complete your application',
    // fi: '',
    nb: 'Fullfør søknad',
  }),
  'Complete your application to receive offers': () => ({
    en: 'Complete your application to receive offers.',
    // fi: '',
    nb: 'Du må fullføre søknaden din for å motta tilbud.',
  }),
  'Please wait': () => ({
    en: 'Please wait',
    fi: 'Odota hetki',
    nb: 'Vennligst vent',
  }),
  'We have received your application and are awaiting responses': () => ({
    en: 'We have received your application and are awaiting responses from the banks. Often, the first offers arrive within a few minutes.',
    fi: 'Hakemustasi käsitellään parhaillaan – päivitämme tilanteen pian!',
    nb: 'Vi har mottatt din søknad og venter på svar fra bankene. Ofte kommer de første tilbudene i løpet av et par minutter.',
  }),
  'We will contact you': () => ({
    en: 'We will contact you',
    fi: 'Olemme sinuun yhteydessä pian',
    nb: 'Vi vil kontakte deg',
  }),
  'It takes longer than expected to process your application': () => ({
    en: 'It takes longer than expected to process your application. We will contact you as soon as your application has been processed.',
    fi: 'Hakemuksen käsittelyssä menee odotettua pidempi aika. Olemme heti yhteydessä, kun hakemus on käsitelty.',
    nb: 'Det tar lengre tid enn forventet å behandle søknaden din. Vi kontakter deg så fort søknaden er ferdig behandlet.',
  }),
  'Your application is being worked on': () => ({
    en: 'Your application is being worked on',
    fi: 'Hakemustasi käsitellään',
    nb: 'Søknaden er under arbeid',
  }),
  'We have received your application and are working': () => ({
    en: 'We have received your application and are working to give you the best offer.',
    fi: 'Olemme vastaanottaneet hakemuksesi ja työskentelemme parhaan tarjouksen löytämiseksi.',
    nb: 'Vi har mottatt søknaden din og jobber for å gi deg det beste tilbudet.',
  }),
  'Start a new application': () => ({
    en: 'Start a new application',
    fi: 'Aloita uusi hakemus',
    nb: 'Start en ny søknad',
  }),
  'You have no active application. Start new': () => ({
    en: 'You have no active application. Click below to start a new application.',
    fi: 'Sinulla ei ole aktiivista hakemusta. Aloita uusi hakemus alla olevasta painikkeesta.',
    nb: 'Du har ingen aktiv søknad. Trykk her under for å starte en ny søknad.',
  }),
  'Unfortunately, you did not receive any loan offers': () => ({
    en: 'Unfortunately, you did not receive any loan offers from our banking partners. Try again later, or contact us, and we will try to assist you further.',
    fi: 'Valitettavasti et saanut yhtään lainatarjousta pankkikumppaneiltamme. Ole yhteydessä meihin avun saamiseksi, tai tee uusi hakemus myöhemmin.',
    nb: 'Du fikk dessverre ingen lånetilbud fra våre bankpartnere. Prøv igjen senere, eller ta kontakt med oss så vil vi prøve å hjelpe deg videre.',
  }),
  'You already have an active application': () => ({
    en: 'You already have an active application',
    fi: 'Edellinen hakemuksesi on vielä voimassa',
    nb: 'Du har allerede en aktiv søknad',
  }),
  'You already have an active application with us': () => ({
    en: 'You already have an active application with us. Log in to My Page with BankID to check your offers. Do you want to make changes to your application? Contact us and we will help you.',
    fi: 'Pääset siihen aiemmin saamasi tekstiviestin kautta. Mikäli haluat tehdä muutoksia hakemukseesi, ole yhteydessä meihin, tai tee uusi hakemus myöhemmin.',
    nb: 'Du har allerede en aktiv søknad hos oss. Logg inn på Mine Tilbud med BankID for å sjekke dine tilbud. Ønsker du å gjøre endringer i søknaden? Kontakt oss så hjelper vi deg.',
  }),
  'You have received an offer': () => ({
    en: 'You have received an offer',
    fi: 'Valitse lainatarjous',
    nb: 'Du har mottatt tilbud',
  }),
  'See available offers and make your choice': () => ({
    en: 'See available offers and make your choice in the list below.',
    fi: 'Tutustu alla oleviin lainatarjouksiin ja tee valintasi.',
    nb: 'Se tilgjengelig tilbud og gjør ditt valg i listen under.',
  }),
  'Complete the agreement by signing': () => ({
    en: "Complete the agreement by signing on the bank's website",
    fi: 'Allekirjoita lainasopimus siirtymällä pankin sivulle',
    nb: 'Fullfør avtalen ved å signere på bankens hjemmeside',
  }),
  'To complete the agreement, you must sign': () => ({
    en: "To complete the agreement, you must sign on the bank's website.",
    fi: 'Viimeistele lainatarjous pankin kanssa siirtymällä painikkeen kautta heidän sivulle.',
    nb: 'For å fullføre avtalen må du signere på bankens hjemmeside.',
  }),
  'Your application is closed. Please contact us': () => ({
    en: 'Your application is closed. Please contact us if you have any questions.',
    fi: 'Hakemuksesi on suljettu. Ole yhteydessä meihin, mikäli sinulla on kysyttävää.',
    nb: 'Din søknad er avsluttet. Ta kontakt med oss dersom du har spørsmål.',
  }),
  'Thank you for choosing Axo Finans': () => ({
    en: 'Thank you for choosing Axo Finans',
    fi: 'Kiitos, että valitsit Axolainan!',
    nb: 'Takk for at du valgte Axo Finans',
  }),
  'Your application has been processed': () => ({
    en: 'Your application has been processed.',
    fi: 'Hakemuksesi on käsitelty.',
    nb: 'Søknaden din er ferdig behandlet.',
  }),
  'Click here': () => ({
    en: 'Click here',
    fi: 'Klikkaa tästä',
    nb: 'Trykk her',
  }),
  // "Click here" for NO, "Start the application" for FI
  'Start the application': () => ({
    en: 'Start the application',
    fi: 'Aloita hakemus',
    nb: 'Trykk her',
  }),
  'Contact us': () => ({
    en: 'Contact us',
    fi: 'Ota yhteyttä',
    nb: 'Kontakt oss',
  }),
  // "Contact us" title with specific FI translation
  'Get in touch with us': () => ({
    en: 'Contact us',
    fi: 'Ole yhteydessä meihin',
    nb: 'Kontakt oss',
  }),
  'Login to My Page': () => ({
    en: 'Login to My Page',
    fi: 'Siirry Omille sivuille',
    nb: 'Logg inn på Mine Tilbud',
  }),

  // Progress bar
  'Awaiting response from banks': () => ({
    en: 'Awaiting response from banks',
    fi: 'Odotetaan vielä pankkien vastausta',
    nb: 'Venter på svar fra banker',
  }),
  'We recommend that you wait until all the banks have responded before you choose an offer':
    () => ({
      en: 'We recommend that you wait until all the banks have responded before you choose an offer.',
      fi: 'Suosittelemme, että odotat kaikkien pankkien vastausta, ennen kuin teet valinnan.',
      nb: 'Vi anbefaler at du venter til alle bankene har svart før du velger et tilbud.',
    }),
  'Please wait for more offers before making a choice': () => ({
    en: 'Please wait for more offers before making a choice. It may take a couple of minutes for all banks to respond...',
    fi: 'Odota vielä muita tarjouksia, ennen kuin teet valintasi. Kaikkien pankkien vastaukset voivat kestää joitakin minuutteja.',
    nb: 'Vennligst vent på flere tilbud før du tar et valg. Det kan ta et par minutter før alle bankene svarer...',
  }),
  'You have received at least one good offer': () => ({
    en: 'You have received at least one good offer!',
    fi: 'Olet saanut ainakin yhden hyvän lainatarjouksen!',
    nb: 'Du har mottatt minst ett godt tilbud!',
  }),
  'Most banks have responded and you can now choose an offer': () => ({
    en: 'Most banks have responded and you can now choose an offer.',
    fi: 'Pankeista valtaosa on vastannut, ja voit nyt valita.',
    nb: 'De fleste bankene har svart, og du kan nå velge et tilbud.',
  }),
  'Waiting for your first offer': () => ({
    en: 'Waiting for your first offer...',
    fi: 'Ensimmäistä tarjousta odotellessa...',
    nb: 'Venter på ditt første tilbud...',
  }),
  'The banks are currently processing your application': () => ({
    en: 'The banks are currently processing your application, it may take a couple of minutes to respond...',
    fi: 'Pankit käsittelevät parhaillaan hakemustasi, voi kestää muutaman minuutin ennen kuin he vastaavat...',
    nb: 'Bankene behandler for øyeblikket søknaden din, det kan ta et par minutter før de svarer...',
  }),
  'All banks have responded': () => ({
    en: 'All banks have responded!',
    fi: 'Kaikki pankit ovat vastanneet!',
    nb: 'Alle bankene har svart!',
  }),
  'You can now select the offer you wish to proceed with': () => ({
    en: 'You can now select the offer you wish to proceed with.',
    fi: 'Voit nyt valita lainatarjouksen, jonka kanssa haluat jatkaa.',
    nb: 'Du kan nå velge tilbud du ønsker å gå videre med.',
  }),
  'Unfortunately, none of our bank partners have made an offer': () => ({
    en: 'Unfortunately, none of our bank partners have made an offer on your application.',
    fi: 'Valitettavasti tällä kertaa et saanut lainatarjouksia pankkikumppaneiltamme.',
    nb: 'Dessverre har ingen av våre bankpartnere gitt et tilbud på søknaden din.',
  }),

  //offer
  Recommended: () => {
    return {
      en: 'Most likely to pay out',
      fi: 'Korkein ulosmaksun todennäköisyys',
      nb: 'Høy utbetalingsgrad',
    };
  },
  Selected: () => {
    return {
      en: 'Selected',
      fi: 'Valittu',
      nb: 'Valgt',
    };
  },
  Disbursed: () => {
    return {
      en: 'Disbursed',
      fi: 'Maksussa',
      nb: 'Utbetalt',
    };
  },
  Dispatched: () => {
    return {
      en: 'Dispatched',
      nb: 'Utsendt',
    };
  },
  Cancelled: () => {
    return {
      en: 'Cancelled',
      nb: 'Avsluttet',
      fi: 'Suljettu tarjous',
    };
  },
  'Previously selected': () => {
    return {
      en: 'Previously selected',
      fi: 'Edellinen valintasi',
      nb: 'Tidligere valgt',
    };
  },
  'Loan amount': () => {
    return {
      en: 'Loan amount',
      fi: 'Lainasumma',
      nb: 'Lånebeløp',
    };
  },
  'Loan period': () => {
    return {
      en: 'Loan period',
      fi: 'Takaisinmaksuaika',
      nb: 'Nedbetalingstid',
    };
  },
  'Nominal interest rate': () => {
    return {
      en: 'Nominal interest rate',
      fi: 'Nimelliskorko',
      nb: 'Nominell rente',
    };
  },
  'Effective interest rate': () => {
    return {
      en: 'Effective interest rate',
      fi: 'Tod. vuosikorko',
      nb: 'Effektiv rente',
    };
  },
  'Monthly pay': () => {
    return {
      en: 'Monthly pay',
      fi: 'Kuukausierä',
      nb: 'Månedskostnad',
    };
  },
  'Loan cost': () => {
    return {
      en: 'Loan cost',
      fi: 'Avausmaksu',
      nb: 'Lånekostnad',
    };
  },
  'Startup fee': () => {
    return {
      en: 'Startup fee',
      fi: 'Aloitusmaksu',
      nb: 'Etableringskostnad',
    };
  },
  'Administration fee': () => {
    return {
      en: 'Administration fee',
      fi: 'Hallintomaksu',
      nb: 'Termingebyr',
    };
  },
  'Documentation required': () => {
    return {
      en: 'Documentation required',
      nb: 'Krever dokumentasjon',
    };
  },
  'Credit limit': () => {
    return {
      en: 'Credit limit',
      fi: 'Luottoraja',
      nb: 'Kredittgrense',
    };
  },
  'Interest free period': () => {
    return {
      en: 'Interest free period',
      fi: 'Korkovapaa aika',
      nb: 'Rentefri periode',
    };
  },
  days: () => {
    return {
      en: 'days',
      fi: 'päivää',
      nb: 'dager',
    };
  },
  'Useful information': () => {
    return {
      en: 'Useful information',
      fi: 'Lisätietoja',
      nb: 'Nyttig informasjon',
    };
  },
  Details: () => {
    return {
      en: 'Details',
      fi: 'Lue lisää',
      nb: 'Detaljer',
    };
  },
  Choose: () => {
    return {
      en: 'Choose',
      fi: 'Valitse',
      nb: 'Velg',
    };
  },
  Continue: () => {
    return {
      en: 'Continue',
      fi: 'Jatka',
      nb: 'Fortsett',
    };
  },
  years: () => {
    return {
      en: 'years',
      fi: 'vuotta',
      nb: 'år',
    };
  },
  year: () => {
    return {
      en: 'year',
      fi: 'vuosi',
      nb: 'år',
    };
  },
  months: () => {
    return {
      en: 'months',
      fi: 'kuukautta',
      nb: 'måneder',
    };
  },
  month: () => {
    return {
      en: 'month',
      fi: 'kuukausi',
      nb: 'måned',
    };
  },
  'per month': () => {
    return {
      en: 'month',
      fi: 'kk',
    };
  },
  'All banks have individual interest rates, and your interest rate is presented first along with the loan offer':
    () => {
      return {
        en: 'All banks have individual interest rates, and your interest rate is presented first along with the loan offer. The repayment period is 1 to 20 years, with a maximum of 5 years if you do not refinance. The nominal interest rate varies from a minimum of 6.99% to a maximum of 23.4%. The effective interest rate varies from a minimum of 8.19% to a maximum of 26.23%. Example: Nominal interest rate 11.9%, effective interest rate 13.71%, loan amount NOK 100,000 with repayment over 5 years, cost NOK 35,914, total NOK 135,914. Establishment fees vary from NOK 0 to NOK 1,990.',
        fi: 'Kaikilla pankeilla on yksilölliset korkotasot, ja korkotasosi esitetään ensin yhdessä lainatarjouksen kanssa. Takaisinmaksuaika on 1 - 20 vuotta, enintään 5 vuotta, jos et uudelleenrahoita lainaasi. Nimelliskorko vaihtelee vähimmäisestä 6,99 % enimmäiseen 23,4 %. Tehokas korko vaihtelee vähimmäisestä 8,19 % enimmäiseen 26,23 %. Esimerkki: Nimelliskorko 11,9 %, tehokas korko 13,71 %, lainasumma 100 000 NOK takaisinmaksuaikana 5 vuotta, kustannus 35 914 NOK, yhteensä 135 914 NOK. Perustamismaksut vaihtelevat 0 NOK:sta 1990 NOK:iin.',
        nb: 'Alle bankene har individuell rente og din rente presenteres først sammen med lånetilbudet. Nedbetalingstiden er 1 til 20 år, maks 5 år dersom du ikke refinansierer. Nominell rente varierer fra minimum 6,99% til maksimum 23,4%. Effektiv rente varierer fra minimum 8,19% til maksimum 26,23%. Eksempel: Nominell rente 11,9%, effektiv rente 13,71%, lånebeløp kr 100 000 med nedbetaling over 5 år, kostnad kr 35 914, totalt kr 135 914. Etableringsgebyr varierer fra kr 0,- til 1990,-.',
      };
    },
  'Loan Offers': () => {
    return {
      en: 'Loan Offers',
      nb: 'Lånetilbud',
    };
  },
  'Credit Cards': () => {
    return {
      en: 'Credit Cards',
      nb: 'Kredittkorttilbud',
    };
  },
  'Closed offer. To reactivate your offer please contact our customer service':
    () => {
      return {
        en: 'Closed offer. To reactivate your offer please contact our customer service',
        fi: 'Suljettu tarjous. Uudelleenaktivointia varten ota yhteyttä asiakaspalveluumme.',
        nb: 'Tilbudet er ikke lenger aktivt. For å gjenaktivere tilbudet ditt, vennligst ta kontakt med kundeservice.',
      };
    },
  'This offer is no longer active': () => {
    return {
      en: 'This offer is no longer active.',
      fi: 'Suljettu tarjous.',
      nb: 'Tilbudet er ikke lenger aktivt. ',
    };
  },
  //accept offer modal
  'Congratulations on you offer': () => {
    return {
      en: 'Congratulations on you offer',
      nb: 'Gratulerer med et godt valg',
      fi: 'Onneksi olkoon tarjouksestasi',
    };
  },
  'Would you also like to see our income insurance offer?': () => {
    return {
      en: 'Would you also like to see our income insurance offer?',
      nb: 'Visste du at vi tilbyr markedets beste utgiftsforsikring?',
      fi: 'Haluatko tutustua tulovakuutustarjoukseemme?',
    };
  },
  'Confirm your selection': () => {
    return {
      en: 'Confirm your selection',
      fi: 'Vahvista valintasi',
      nb: 'Bekreft ditt valg',
    };
  },
  'You have chosen the offer from': () => {
    return {
      en: 'You have chosen the offer from',
      fi: 'Olet valinnut tarjouksen kumppaniltamme',
      nb: 'Du har valgt tilbudet fra',
    };
  },
  'Do you want to continue with': () => {
    return {
      en: 'Do you want to continue with',
      fi: 'Haluatko hyväksyä tarjouksen pankilta',
      nb: 'Vil du fortsette med',
    };
  },
  'Axo Laina Insurance': () => ({
    en: 'Axo Laina Insurance',
    fi: 'Axolaina Maksuturva',
    nb: 'Axo utgiftsforsikring',
  }),
  'Proceed to loan agreement': (params: Params) => ({
    en: (
      <p>
        By choosing this option, you proceed to the <b>{params[0]}</b> website
        to sign the loan agreement.
      </p>
    ),
    fi: (
      <p>
        Valitsemalla tämän vaihtoehdon siirryt <b>{params[0]}</b>{' '}
        verkkosivustolle allekirjoittamaan lainasopimuksen.
      </p>
    ),
    nb: (
      <p>
        Når du velger dette alternativet, går du videre til <b>{params[0]}</b>{' '}
        nettsted for å signere låneavtalen.
      </p>
    ),
    sv: (
      <p>
        Genom att välja detta alternativ går du vidare till <b>{params[0]}</b>{' '}
        webbplats för att underteckna låneavtalet.
      </p>
    ),
    da: (
      <p>
        Hvis du vælger denne mulighed, går du videre til <b>{params[0]}</b>{' '}
        hjemmeside for at underskrive låneaftalen.
      </p>
    ),
  }),
  'Proceed to income insurance': (params: Params) => ({
    en: (
      <>
        <p>
          By choosing this option, you can review our income insurance offer
          before you proceed to the <b>{params[0]}</b> website to sign the loan
          agreement.
        </p>
        <p>
          Expense insurance can be a security for you should changes occur in
          your life situation, whether it is related to your work situation or
          health.
        </p>
      </>
    ),
    fi: (
      <>
        <p>
          Valitsemalla tämän vaihtoehdon voit tutustua tulovakuutustarjoukseemme
          ennen kuin siirryt <b>{params[0]}</b> verkkosivustolle
          allekirjoittamaan lainasopimuksen.
        </p>
        <p>
          Kuluvakuutus voi olla sinulle turva, jos elämäntilanteessasi tapahtuu
          muutoksia, liittyivätpä ne sitten työtilanteeseesi tai terveyteesi.
        </p>
      </>
    ),
    nb: (
      <>
        <p>
          Ved å velge dette alternativet kan du se vårt tilbud om
          inntektsforsikring før du går videre til <b>{params[0]}</b> nettsted
          for å signere låneavtalen.
        </p>
        <p>
          En utgiftsforsikring kan være en trygghet for deg dersom det skulle
          skje endringer i livssituasjonen din, enten det er knyttet til
          arbeidssituasjon eller helse.
        </p>
      </>
    ),
    sv: (
      <>
        <p>
          Genom att välja detta alternativ kan du granska vårt erbjudande om
          inkomstförsäkring innan du går vidare till <b>{params[0]}</b>{' '}
          webbplats för att underteckna låneavtalet.
        </p>
        <p>
          En utgiftsförsäkring kan vara en trygghet för dig om det sker
          förändringar i din livssituation, oavsett om det gäller din
          arbetssituation eller hälsa.
        </p>
      </>
    ),
    da: (
      <>
        <p>
          Ved at vælge denne mulighed kan du se vores tilbud om
          indkomstforsikring, før du går videre til <b>{params[0]}</b>{' '}
          hjemmeside for at underskrive låneaftalen.
        </p>
        <p>
          En udgiftsforsikring kan være en sikkerhed for dig, hvis der sker
          ændringer i din livssituation, uanset om det er relateret til din
          arbejdssituation eller dit helbred.
        </p>
      </>
    ),
  }),
  'Waiting for more offers': () => ({
    en: 'Waiting for more offers',
    fi: 'Lisää tarjouksia odotellessa',
    nb: 'Venter på flere tilbud',
  }),

  'Confirm and proceed to bank page': () => {
    return {
      en: 'Confirm and proceed to bank page',
      fi: 'Hyväksy ja jatka pankin sivuille',
      nb: 'Bekreft og gå til bankens side',
    };
  },
  'Congratulations!': () => {
    return {
      en: 'Congratulations!',
      fi: 'Hienoa!',
      nb: 'Gratulerer!',
    };
  },
  'You will receive a confirmation email or SMS shortly.': () => {
    return {
      en: 'You will receive a confirmation email or SMS shortly.',
      fi: 'Tulet pian saamaan vahvistusviestin sähköpostitse tai tekstiviestitse.',
      nb: 'Du vil motta en bekreftelses-e-post eller SMS snart.',
    };
  },

  'Of course': () => {
    return {
      en: 'Of course',
      fi: 'Selvä',
      nb: 'Selvfølgelig',
    };
  },

  //change accept offer modal
  'Change offer selection': () => {
    return {
      en: 'Change offer selection',
      fi: 'Muuta tarjousvalintaa',
      nb: 'Endre tilbudsvalg',
    };
  },
  'Change your selection': () => {
    return {
      en: 'Change your selection',
      fi: 'Muuta valintaasi',
      nb: 'Endre ditt valg',
    };
  },
  'Currently selected': () => {
    return {
      en: 'Currently selected',
      fi: 'Aiemmin valittu',
      nb: 'Nåværende valg',
    };
  },
  'New selection': () => {
    return {
      en: 'New selection',
      fi: 'Uusi valinta',
      nb: 'Nytt valg',
    };
  },
  'Confirm and proceed': () => {
    return {
      en: 'Confirm and continue',
      fi: 'Vahvista ja jatka',
      nb: 'Bekreft og fortsett',
    };
  },
  'Are you sure you want to change your selection?': () => {
    return {
      en: 'Are you sure you want to change your selection?',
      fi: 'Haluatko varmasti vaihtaa lainatarjouksesi?',
      nb: 'Er du sikker på at du vil endre ditt valg?',
    };
  },
  'You are changing your loan offer. Please notice that the previous selection will be closed/cancelled and you can not reselect it':
    () => {
      return {
        en: 'You are changing your loan offer. Please notice that the previous selection will be closed/cancelled and you can not reselect it',
        fi: 'Olet vaihtamassa lainatarjoustasi. Huomaathan, että tällöin aiemmin valittu tarjous sulkeutuu eikä aiempaa tarjousta voi enää valita uudelleen.',
        nb: 'Du er i ferd med å endre lånetilbudet ditt. Vær oppmerksom på at ditt tidligere valg vil bli lukket, og det kan kun reaktiveres ved å kontakte kundeservice.',
      };
    },

  //error
  'An error occured': () => {
    return {
      en: 'An error occured',
      fi: 'Tapahtui virhe',
      nb: 'Det oppstod en feil',
    };
  },
  'There was an issue fetching your offers. Please try again or contact support at':
    () => {
      return {
        en: 'There was an issue fetching your offers. Please try again or contact support at',
        fi: 'Tarjousten lataamisessa tapahtui virhe. Päivitä sivu ja yritä uudelleen tai ota yhteyttä asiakaspalveluun numeroon',
        nb: 'Det oppstod et problem med å hente tilbudene dine. Vennligst prøv igjen eller kontakt brukerstøtten på',
      };
    },
  'A technical error has occured. Please try again or contact support at':
    () => {
      return {
        en: 'A technical error has occured. Please try again or contact support at',
        fi: 'Tapahtui tekninen virhe. Päivitä sivu ja yritä uudelleen tai ota yhteyttä asiakaspalveluumme',
        nb: 'Det har oppstått en teknisk feil. Vennligst prøv igjen eller kontakt brukerstøtten på',
      };
    },
  'It appears that we are experiencing technical difficulties': () => {
    return {
      en: 'It appears that we are experiencing technical difficulties at the moment. We will resolve the issue as soon as possible. We apologize for the inconvenience caused.',
      fi: 'Näyttää siltä, että meillä on tällä hetkellä teknisiä vaikeuksia. Pyrimme ratkaisemaan ongelman mahdollisimman pian. Pahoittelemme tästä aiheutuvaa haittaa.',
      nb: 'Det ser ut til at vi opplever tekniske problemer for øyeblikket. Vi vil løse problemet så snart som mulig. Vi beklager ulempen dette medfører.',
    };
  },
  'To fix the error, you can try refreshing the page. Please contact our customer service at':
    () => {
      return {
        en: 'To fix the error, you can try refreshing the page or try again later. If the problem persists, please contact our customer service at',
        fi: 'Virheen korjaamiseksi voit kokeilla sivun päivittämistä tai yrittää myöhemmin uudelleen. Ongelman jatkuessa otathan yhteyttä asiakaspalveluumme numeroon',
        nb: 'For å rette opp feilen kan du prøve å oppdatere siden eller prøve igjen senere. Hvis problemet vedvarer, vennligst kontakt kundeservice på',
      };
    },
  'An error has occured while processing your insurance. Please continue with your loan and contact customer service at':
    () => {
      return {
        en: 'An error has occured while processing your insurance. Please continue with your loan and contact customer service at',
        fi: 'Vakuutuksesi käsittelyssä tapahtui virhe. Jatka lainahakemustasi ja ota yhteyttä asiakaspalveluumme numeroon',
        nb: 'Det har oppstått en feil under behandlingen av forsikringen din. Vennligst fortsett med lånet ditt og kontakt kundeservice på',
      };
    },
  'The offer you have selected is no longer available. Please select another offer.':
    () => {
      return {
        en: 'The offer you have selected is no longer available. Please select another offer.',
        fi: 'Valitsemasi lainatarjous ei ole enää saatavilla. Ole hyvä ja valitse toinen tarjous.',
        nb: 'Tilbudet du har valgt er ikke lenger tilgjengelig. Vennligst velg et annet tilbud.',
      };
    },

  //faq
  'Frequently asked questions': () => ({
    en: 'Common questions',
    fi: 'Yleisiä kysymyksiä',
    nb: 'Vanlige spørsmål',
  }),
  // Unsecured Loan FAQ
  'UL FAQ Question 1': () => ({
    en: 'Why did I receive a negative loan decision?',
    fi: 'Miksi sain kielteisen lainapäätöksen?',
    nb: 'Hvordan velger jeg lånetilbud?',
  }),
  'UL FAQ Answer 1': () => ({
    en: 'A bank may provide a negative loan decision if the applicant does not meet the minimum requirements for loan approval. Possible reasons for a negative loan decision may include a payment default entry, not listing all existing loans on the loan application, or providing incorrect income information on the loan application. Banks always process applications on a case-by-case basis, and if necessary, you can contact the bank that issued the negative credit decision.',
    fi: 'Pankki voi antaa kielteisen lainapäätöksen, mikäli hakija ei täytä lainan myöntämiselle asetettuja vähimmäisvaatimuksia. Mahdollisia syitä kielteiselle lainapäätökselle saattaa olla esimerkiksi maksuhäiriömerkintä, kaikkien olemassa olevien lainojen merkitsemättä jättäminen lainahakemukseen tai virheelliset tulotiedot lainahakemuksessa. Pankit käsittelevät hakemuksen aina asiakaskohtaisesti, ja tarvittaessa voit ottaa yhteyttä kielteisen luottopäätöksen antaneeseen pankkiin.',
    nb: 'Klikk på den gule knappen ved det lånetilbudet du ønsker å gå videre med. Straks du har gjort dette kan du klikke deg videre og fullføre prosessen på banken/långiveren sin hjemmeside.',
  }),
  'UL FAQ Question 2': () => ({
    en: 'I received a negative loan decision. When can I reapply for a loan?',
    fi: 'Sain kielteisen lainapäätöksen, milloin voin hakea lainaa uudelleen?',
    nb: 'Hva skjer når jeg har valgt lånetilbud?',
  }),
  'UL FAQ Answer 2': () => ({
    en: 'You can reapply for a loan after 30 days have passed since the submission of your previous loan application.',
    fi: 'Voit hakea lainaa uudelleen 30 päivän kuluttua edellisen lainahakemuksesi lähettämisestä.',
    nb: 'Straks du har valgt lånetilbud vil du få muligheten til å fortsette prosessen på bankens/långivers hjemmeside. De sender deg også informasjon om hva du må gjøre for å få lånet utbetalt.',
  }),
  'UL FAQ Question 3': () => ({
    en: 'How can I improve my chances of getting a loan?',
    fi: 'Miten voin parantaa mahdollisuuksiani saada laina?',
    nb: 'Kan jeg endre lånetilbud etter at jeg har valgt?',
  }),
  'UL FAQ Answer 3': () => ({
    en: 'You can increase your chances of loan approval by ensuring that you apply for only the amount of loan you truly need. If you have multiple unsecured loans, consider consolidating them into one larger loan. You can also work on improving your financial situation by creating a monthly budget, cutting expenses, or refinancing your existing loans for better terms.',
    fi: 'Voit lisätä lainan saamisen mahdollisuuksia varmistamalla, että haet vain tarvitsemasi lainasumman. Jos sinulla on useita vakuudettomia lainoja, harkitse niiden yhdistämistä yhdeksi suuremmaksi lainaksi. Voit myös parantaa taloudellista tilannettasi luomalla kuukausibudjetin, leikkaamalla kuluja tai kilpailuttamalla olemassa olevat lainasi paremmilla ehdoilla.',
    nb: 'Ja, det kan du. Ta kontakt med en av våre hyggelige medarbeidere hos kundeservice, så hjelper vi deg. Du kan nå kundeservice på telefon +47 22 86 74 00, via e-post til kundeservice@axofinans.no, eller gjennom chat på vår hjemmeside. Vi holder åpent hver dag.',
  }),
  'UL FAQ Question 4': () => ({
    en: '',
    nb: 'Hvor lang tid tar det før alle bankene har gitt sine lånetilbud?',
  }),
  'UL FAQ Answer 4': () => ({
    en: '-',
    nb: 'Dette tar vanligvis noen minutter, men på kveldstid og i helger kan det ta noe lenger tid. Vi sender deg en SMS/e-post straks søknaden er ferdig behandlet, og alle lånetilbud er mottatt.',
  }),
  'UL FAQ Question 5': () => ({
    en: 'I received a negative loan decision. When can I reapply for a loan?',
    nb: 'Hvorfor fikk jeg ikke det lånebeløpet jeg søkte om?',
  }),
  'UL FAQ Answer 5': () => ({
    en: 'You can reapply for a loan after 30 days have passed since the submission of your previous loan application.',
    nb: 'Alle banker og långivere behandler hver søknad individuelt. De vurderer din økonomiske situasjon og fremtidig betalingsevne. I noen tilfeller betyr dette at tilbudt lånebeløp kan være lavere enn det du søkte om. I noen tilfeller kan det også være høyere enn det du søkte om. Typisk skjer dette dersom banken ønsker å refinansiere eksisterende gjeld i tillegg til de pengene du ønsker utbetalt.',
  }),
  'UL FAQ Question 6': () => ({
    en: 'I received a negative loan decision. When can I reapply for a loan?',
    nb: 'Kan jeg nedbetale lånet raskere enn avtalt?',
  }),
  'UL FAQ Answer 6': () => ({
    en: 'You can reapply for a loan after 30 days have passed since the submission of your previous loan application.',
    nb: 'Ja. Du kan når som helst gjøre ekstra innbetalinger på lånet, eller innfri det i sin helhet. Dette styrer du selv og det medfører ingen ekstra kostnader. Nedbetalingstiden er fleksibel.',
  }),
  'UL FAQ Question 7': () => ({
    en: 'I received a negative loan decision. When can I reapply for a loan?',
    nb: 'Hva er forskjellen på nominell og effektiv rente?',
  }),
  'UL FAQ Answer 7': () => ({
    en: 'You can reapply for a loan after 30 days have passed since the submission of your previous loan application.',
    nb: 'Effektiv rente er den totale prisen du betaler for lånet. Det vil si, den nominelle renten i tillegg til etableringsgebyr, termingebyr og eventuelt andre kostnader knyttet til lånet. Disse kostnadene gjør at den effektive renten alltid er høyere enn den nominelle renten.',
  }),
  'UL FAQ Question 8': () => ({
    en: 'I received a negative loan decision. When can I reapply for a loan?',
    nb: 'Hva er etablerings- og termingebyr?',
  }),
  'UL FAQ Answer 8': () => ({
    en: 'You can reapply for a loan after 30 days have passed since the submission of your previous loan application.',
    nb: 'Etableringsgebyr er en kostnad man betaler for opprettelse av lånet. Termingebyr er en månedlig kostnad for å dekke bankens administrative kostnader knyttet til lånet.',
  }),
  'UL FAQ Question 9': () => ({
    en: 'I received a negative loan decision. When can I reapply for a loan?',
    nb: 'Hva menes med at banken krever dokumentasjon?',
  }),
  'UL FAQ Answer 9': () => ({
    en: 'You can reapply for a loan after 30 days have passed since the submission of your previous loan application.',
    nb: 'Banken opplyser om det er nødvendig for deg å sende dokumenter som bekrefter din inntekt og skattegrunnlag. Ved å samtykke med BankID kan banken enkelt og trygt innhente informasjonen de behøver fra Altinn.',
  }),
  // Credit Cards FAQ
  'CC FAQ Question 1': () => ({
    en: 'How do I choose a credit card?',
    nb: 'Hvordan velger jeg kredittkort?',
  }),
  'CC FAQ Answer 1': () => ({
    en: "Click on the yellow button next to the credit card you wish to proceed with. Once you have done this, you can click through and complete the process on the bank/lender's website.",
    nb: 'Klikk på den gule knappen ved det kredittkortet du ønsker å gå videre med. Straks du har gjort dette kan du klikke deg videre og fullføre prosessen på banken/långiveren sin hjemmeside.',
  }),
  'CC FAQ Question 2': () => ({
    en: 'What happens once I have chosen my credit card?',
    nb: 'Hva skjer når jeg har valgt kredittkort?',
  }),
  'CC FAQ Answer 2': () => ({
    en: "Once you have chosen your credit card, you will be given the option to continue the process on the bank/lender's website. They will also send you information about what you need to do to get the credit card.",
    nb: 'Straks du har valgt ditt kredittkort vil du få muligheten til å fortsette prosessen på bankens/långivers hjemmeside. De sender deg også informasjon om hva du må gjøre for å få kredittkortet.',
  }),
  'CC FAQ Question 3': () => ({
    en: 'Can I change my credit card after I have chosen?',
    nb: 'Kan jeg endre kredittkort etter at jeg har valgt?',
  }),
  'CC FAQ Answer 3': () => ({
    en: "Yes, you can. Get in touch with one of our friendly customer service staff and we'll help you. You can reach customer service by phone on +47 22 86 74 00, by email to kundeservice@axofinans.no, or through chat on our website. We are open every day.",
    nb: 'Ja, det kan du. Ta kontakt med en av våre hyggelige medarbeidere hos kundeservice, så hjelper vi deg. Du kan nå kundeservice på telefon +47 22 86 74 00, via e-post til kundeservice@axofinans.no, eller gjennom chat på vår hjemmeside. Vi holder åpent hver dag.',
  }),
  'CC FAQ Question 4': () => ({
    en: 'How long does it take for my application to be processed by the banks?',
    nb: 'Hvor lang tid tar det før søknaden min er ferdig behandlet av bankene?',
  }),
  'CC FAQ Answer 4': () => ({
    en: "The banks have up to 24 hours to consider your application, but usually this is much faster. On weekends, it may take a little longer. We'll let you know as soon as all the banks have given an answer!",
    nb: 'Bankene har opptil 24 timer på å vurdere søknaden din, men som regel går dette mye raskere. I helgene kan det ta noe lengre tid. Vi gir deg beskjed så fort alle bankene har gitt et svar!',
  }),
  'CC FAQ Question 5': () => ({
    en: 'What is the difference between nominal and effective interest rates on credit cards?',
    nb: 'Hva er forskjellen på nominell og effektiv rente på kredittkort?',
  }),
  'CC FAQ Answer 5': () => ({
    en: 'The nominal interest rate is the basic annual interest rate on your credit card, without any extra charges. Effective interest rate, on the other hand, includes all charges such as fees and compound interest, and gives a more accurate picture of the total annual cost of using your credit card. While the nominal interest rate helps compare base costs, the effective interest rate reflects the real full cost.',
    nb: 'Nominell rente er den grunnleggende årlige renten på kredittkortet, uten ekstrakostnader. Effektiv rente derimot, inkluderer alle kostnader som gebyrer og sammensatt rente, og gir et mer nøyaktig bilde av de totale årlige kostnadene ved å bruke kredittkortet. Mens nominell rente hjelper med å sammenligne grunnkostnader, reflekterer effektiv rente den reelle fullstendige kostnaden.',
  }),
  'CC FAQ Question 6': () => ({
    en: 'Why is my application rejected?',
    nb: 'Hvorfor får jeg avslag på søknaden min?',
  }),
  'CC FAQ Answer 6': () => ({
    en: 'Getting a loan offer usually depends on a number of different factors. The decision made by the banks stems from a thorough review of your application and your credit information. The banks also take into account whether the loan you are applying for could be a financial risk for you and whether they are prepared to take this risk themselves. Furthermore, banks are obliged to advise against lending to customers who represent a high risk.',
    nb: 'Det å få et lånetilbud avhenger vanligvis av en rekke ulike faktorer. Beslutningen bankene har tatt, stammer fra en grundig gjennomgang av søknaden din og dine kredittopplysninger. Bankene tar også hensyn til om lånet du søker om kan være en økonomisk risiko for deg, og om de selv er beredt til å ta denne risikoen. Videre er bankene forpliktet til å fraråde lånetaking til kunder som representerer en høy risiko.',
  }),
  'CC FAQ Question 7': () => ({
    en: 'I received a negative loan decision. When can I reapply for a loan?',
    nb: 'Hva menes med at banken krever dokumentasjon?',
  }),
  'CC FAQ Answer 7': () => ({
    en: 'You can reapply for a loan after 30 days have passed since the submission of your previous loan application.',
    nb: 'Banken opplyser om det er nødvendig for deg å sende dokumenter som bekrefter din inntekt og skattegrunnlag. Ved å samtykke med BankID kan banken enkelt og trygt innhente informasjonen de behøver fra Altinn.',
  }),

  // NextSteps (flow chart)
  'Select an offer': () => {
    return {
      en: 'Select an offer',
      nb: 'Velg et tilbud',
    };
  },
  'Select the bank and the offer that matches': () => {
    return {
      en: 'Select the bank and the offer that matches',
      nb: 'Velg banken og tilbudet som passer deg best',
    };
  },
  'Complete the process': () => {
    return {
      en: 'Complete the process',
      nb: 'Fullfør prosessen',
    };
  },
  "Complete the process on the bank's web site": () => {
    return {
      en: "Complete the process on the bank's web site",
      nb: 'Fortsett prosessen på bankens hjemmesider',
    };
  },
  'Have the loan paid out': () => {
    return {
      en: 'Have the loan paid out',
      nb: 'Få pengene utbetalt',
    };
  },
  'Follow the instructions to have the loan paid out': () => {
    return {
      en: 'Follow the instructions to have the loan paid out',
      nb: 'Følg instruksjonene fra banken og få pengene utbetalt',
    };
  },

  // Loan type explanations
  'The loan offer is a consumer loan': () => {
    return {
      en: 'The loan offer is a consumer loan where you can use the money for whatever you want',
      nb: 'Lånetilbudet er et forbrukslån hvor du kan bruke pengene til hva du vil.',
      fi: 'Lainatarjous on kulutusluotto, jonka voit käyttää haluamaasi tarkoitukseen.',
    };
  },
  'The loan offer is a refinancing loan': () => {
    return {
      en: 'The loan offer is a refinancing loan where old and expensive debt is replaced with a cheaper loan.',
      nb: 'Lånetilbudet er et refinansieringslån hvor gammel og dyr gjeld blir erstattet med et billigere lån.',
      fi: 'Lainatarjous on lainasi uudelleenrahoitusta varten, jolla vanha ja kallis velka korvataan yhdellä edullisella lainalla.',
    };
  },
  'The loan offer above includes your existing loan with this bank': () => {
    return {
      en: 'The loan offer above includes your existing loan with this bank.',
      nb: 'Lånetilbudet over inkluderer ditt eksisterende lån hos denne banken.',
      fi: 'Lainatarjous yllä sisältää nykyisen lainasi tässä pankissa.',
    };
  },
  'The loan offer is a combination of refinancing loan and consumer loan, where part of the loan goes to replace old and expensive debt, and the rest is paid into your account.':
    () => {
      return {
        en: 'The loan offer is a combination of refinancing loan and consumer loan, where part of the loan goes to replace old and expensive debt, and the rest is paid into your account.',
        nb: 'Lånetilbudet er en kombinasjon av refinansieringslån og forbrukslån, hvor noe av lånet går til å erstatte gammel og dyr gjeld, og resten blir utbetalt til din konto.',
        fi: 'Lainatarjous on sekä uudelleenrahoitusta että kulutusluottoa, jossa osa käytetään vanhan ja kalliin velan korvaamiseen, ja loput maksetaan tilillesi.',
      };
    },
  'The loan offer is a combination of refinancing loan and consumer loan': (
    params: Params
  ) => {
    return {
      en: (
        <>{`The loan offer is a combination of refinancing loan and consumer loan, where ${params[0]} goes to replace old and expensive debt, and ${params[0]} is paid to your account.`}</>
      ),
      nb: (
        <>
          {`Lånetilbudet er en kombinasjon av refinansieringslån og forbrukslån, hvor ${params[0]} går til å erstatte gammel og dyr gjeld, og ${params[1]} blir utbetalt til din konto.`}
        </>
      ),
      fi: (
        <>
          {`Lainatarjous on sekä uudelleenrahoitusta että kulutusluottoa, jossa ${params[0]} käytetään vanhan ja kalliin velan korvaamiseen, ja ${params[1]} maksetaan tilillesi.`}
        </>
      ),
    };
  },
  'This loan offer is an alternative refinancing loan': () => {
    return {
      en: 'This loan offer is an alternative refinancing loan based on information from the debt register, which repays more of your debt for a cheaper loan.',
      nb: 'Dette lånetilbudet er et alternativt refinansieringslån basert på opplysninger fra gjeldsregister, som innfrir mer av gjelden din til et billigere lån.',
      fi: 'Tämä lainatarjous on vaihtoehtoinen uudelleenrahoitus, joka perustuu velkarekisterin tietoihin ja jolla velkaasi voi maksaa edullisemmalla lainalla.',
    };
  },
  'You have not applied for any loans': () => {
    return {
      en: 'You have not applied for any loans',
      nb: 'Du har ikke søkt om noen lån',
      fi: 'Ei avointa lainahakemusta.',
    };
  },
  'You have not applied for any credit cards': () => {
    return {
      en: 'You have not applied for any credit cards',
      nb: 'Du har ikke søkt om noen kredittkort',
    };
  },
  'Complete our form now and start receiving offers': () => {
    return {
      en: 'Complete now our form and start receiving offers, it’s a 100% free and non binding process',
      nb: 'Fyll ut skjemaet vårt og start motta tilbud, det er 100% gratis og uforpliktende',
      fi: 'Täytä hakemus nyt ja vastaanota tarjouksia. Hakemus on täysin maksuton eikä se sido sinua.',
    };
  },
  'Apply now': () => {
    return {
      en: 'Apply now',
      nb: 'Søk nå',
      fi: 'Hae nyt',
    };
  },

  //Login
  'Login with BankID': () => {
    return {
      en: 'Login with BankID',
      nb: 'Logg inn med BankID',
    };
  },
  'There has been an error': () => {
    return {
      en: 'There has been an error',
      nb: 'Det har skjedd en feil',
    };
  },
  'There has been an error with BankID, please try again': () => {
    return {
      en: 'There has been an error with BankID, please try again.',
      nb: 'BankID-innlogging mislyktes, vennligst prøv igjen.',
    };
  },
  'Use BankID to log in and check your offers on MyPage': () => {
    return {
      en: 'Use BankID to log in and check your offers on MyPage',
      nb: 'Bruk BankID for å logge inn og sjekke tilbudene dine på Mine Tilbud.',
    };
  },
  'Don’t have BankID? Contact customer service at': () => {
    return {
      en: (
        <>
          Don’t have BankID? <br /> Contact customer service at{' '}
          <a>00 11 22 33</a>
        </>
      ),
      nb: (
        <>
          Har du ikke BankID?
          <br /> Kontakt kundestøtte på <a href="tel:22867400">22 86 74 00</a>
        </>
      ),
    };
  },
};
