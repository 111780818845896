import { SectionTabs } from '@axo/mypage/ui/atom';
import { useTranslation } from '@axo/mypage/util/translation';
import {
  useApplicationStatusUtils,
  useLoanQuotePresentation,
} from '@axo/shared/data-access/hooks';
import {
  loan_application,
  loan_quote_accept,
  Product,
} from '@axo/shared/data-access/types';
import { Icons } from '@axo/shared/ui/atoms';
import { ComponentType, useCallback, useContext } from 'react';
import { ApplicationProgressContext } from '../../../ApplicationProgressProvider';
import { LoanQuotePresentation } from '../../../LoanQuotePresentation';
import { NextSteps } from '../../../NextSteps';
import { NoApplication } from '../../../NoApplication';
import styles from '../LoanQuoteList.module.scss';

type IQuoteTabs = {
  hasNextSteps?: boolean;
  hasCostExample?: boolean;
  unsecuredLoanApplication: loan_application.LoanApplication | null | undefined;
  creditCardApplication: loan_application.LoanApplication | null | undefined;
  AcceptOfferModalWithInsurance: ComponentType<loan_quote_accept.TAcceptOfferModal>;
  handleActiveProduct: (e: Product) => void;
  activeProduct: Product;
};

export function QuoteTabs({
  hasNextSteps,
  hasCostExample,
  unsecuredLoanApplication,
  creditCardApplication,
  activeProduct,
  handleActiveProduct,
  AcceptOfferModalWithInsurance,
}: IQuoteTabs) {
  const { dispatch } = useContext(ApplicationProgressContext);
  const { t } = useTranslation();
  const { isInAllApplicationStatusGroups } = useApplicationStatusUtils();

  const { data: unsecuredLoanQuotes } = useLoanQuotePresentation(
    unsecuredLoanApplication?.ID
  );
  const { data: creditCardQuotes } = useLoanQuotePresentation(
    creditCardApplication?.ID
  );

  const showProcess =
    activeProduct === Product.UnsecuredLoan
      ? !!unsecuredLoanQuotes?.AcceptableLoanQuotes.length &&
        isInAllApplicationStatusGroups(
          unsecuredLoanApplication?.Status as loan_application.ApplicationStatus,
          [
            loan_application.StatusGroup.Active,
            loan_application.StatusGroup.Registered,
          ]
        )
      : !!creditCardQuotes?.AcceptableLoanQuotes.length &&
        isInAllApplicationStatusGroups(
          creditCardApplication?.Status as loan_application.ApplicationStatus,
          [
            loan_application.StatusGroup.Active,
            loan_application.StatusGroup.Registered,
          ]
        );

  const handleTabChange = useCallback(
    (product: Product) => {
      handleActiveProduct(product);
      dispatch({ type: 'Reset progressbar' });
    },
    [handleActiveProduct]
  );

  return (
    <>
      <SectionTabs.Root
        value={activeProduct}
        onValueChange={(value) => handleTabChange(value as Product)}
      >
        <SectionTabs.List>
          <SectionTabs.Trigger value={Product.UnsecuredLoan}>
            <span className={styles.tabName}>
              <Icons.MoneyNotes /> {t('Loan Offers')}
            </span>
          </SectionTabs.Trigger>
          <SectionTabs.Trigger value={Product.CreditCard}>
            <span className={styles.tabName}>
              <Icons.CreditCard /> {t('Credit Cards')}
            </span>
          </SectionTabs.Trigger>
        </SectionTabs.List>
        <SectionTabs.Content value={Product.UnsecuredLoan}>
          {unsecuredLoanApplication ? (
            <>
              <LoanQuotePresentation
                applicationID={unsecuredLoanApplication.ID}
                AcceptOfferModalWithInsurance={AcceptOfferModalWithInsurance}
              />
              {hasCostExample && (
                <div className={styles.costExample}>
                  {t(
                    'All banks have individual interest rates, and your interest rate is presented first along with the loan offer'
                  )}
                </div>
              )}
            </>
          ) : (
            <NoApplication
              icon={<Icons.MoneyNotes size="lg" />}
              title={t('You have not applied for any loans') as string}
              description={
                t('Complete our form now and start receiving offers') as string
              }
              buttonLabel={t('Apply now') as string}
            />
          )}
        </SectionTabs.Content>
        <SectionTabs.Content value={Product.CreditCard}>
          {creditCardApplication ? (
            <LoanQuotePresentation
              applicationID={creditCardApplication.ID}
              AcceptOfferModalWithInsurance={AcceptOfferModalWithInsurance}
            />
          ) : (
            <NoApplication
              icon={<Icons.CreditCard size="lg" />}
              title={t('You have not applied for any credit cards') as string}
              description={
                t('Complete our form now and start receiving offers') as string
              }
              buttonLabel={t('Apply now') as string}
            />
          )}
        </SectionTabs.Content>
      </SectionTabs.Root>
      {hasNextSteps && showProcess && <NextSteps />}
    </>
  );
}
