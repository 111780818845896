import { useQuery } from 'react-query';
import { loan_application_api } from '@axo/shared/data-access/api';

import { useContext } from 'react';
import { DataAccessContext, useAPI } from '@axo/shared/data-access/provider';
import { queryFunction } from '../query-function';
import { parsePersonKey, personKeys } from '../cache-keys/personKeys';

export function useLoanApplicationFromPerson(personID: string | undefined) {
  const { state } = useContext(DataAccessContext);
  const api = useAPI();
  const cacheKey = personKeys.loanApplications({
    personID: personID ?? '',
  });

  return useQuery(
    cacheKey,
    queryFunction(
      loan_application_api.findLoanApplicationByPersonID(api),
      ({ queryKey }) => parsePersonKey(queryKey).personID ?? ''
    ),
    {
      enabled: !!state.user.token && !!personID,
    }
  );
}
