import { useQuery } from 'react-query';
import { loan_application_api } from '@axo/shared/data-access/api';
import { customerKeys, parseCustomerKey } from '../cache-keys/customerKeys';

import { useContext } from 'react';
import { DataAccessContext, useAPI } from '@axo/shared/data-access/provider';
import { queryFunction } from '../query-function';

export function useLoanApplicationFromCustomer(customerId: string | undefined) {
  const { state } = useContext(DataAccessContext);
  const api = useAPI();
  const cacheKey = customerKeys.loanApplications({
    customerID: customerId ?? '',
  });

  return useQuery(
    cacheKey,
    queryFunction(
      loan_application_api.findLoanApplicationByCustomerID(api),
      ({ queryKey }) => parseCustomerKey(queryKey).customerID ?? ''
    ),
    {
      enabled: !!state.user.token && !!customerId,
    }
  );
}
