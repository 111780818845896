import {
  getAnonymousUserLDContext,
  getUserLDContext,
} from '@axo/services/launch-darkly/config/getUserLDContext';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import {
  AnalyticsEvent,
  AnalyticsIdentify,
  IAnalytics,
} from '../Analytics.types';
import {
  IAnalyticsService,
  IAnalyticsServiceParams,
} from '../AnalyticsService.types';
import { timeoutAfter } from '../utils/timeoutAfter';
import { waitUntil } from '../utils/waitUntil';

const clientState = { initialized: false };

/**
 * Launch Darkly Service
 *
 * @see https://docs.launchdarkly.com/sdk/concepts/events
 * @see https://docs.launchdarkly.com/sdk/features/events#client-side-sdks
 */
export const LaunchDarklyService: IAnalyticsService = (
  params: IAnalyticsServiceParams = {}
): IAnalytics => {
  const { timeout = 2000 } = params;
  const { client } = params as { client: ReturnType<typeof useLDClient> };

  if (!client) {
    console.warn('LaunchDarklyService client not provided');
  }

  clientState.initialized = false;
  client?.waitForInitialization().then(() => {
    return (clientState.initialized = true);
  });

  const waitForInitialization = () => waitUntil(isInitialized);
  const isInitialized = () => clientState.initialized;

  const track = (e: AnalyticsEvent) => {
    if (!client)
      return Promise.reject(
        new Error('LaunchDarklyService service is not present')
      );

    client.track(e.event, e.params);

    const promise = e.options?.send_immediately
      ? client.flush()
      : Promise.resolve();

    return timeoutAfter(promise, timeout, 'LaunchDarklyService timed out');
  };

  /**
   * @see https://docs.launchdarkly.com/sdk/features/identify
   */

  const identify = async ({ uuid, email }: AnalyticsIdentify) => {
    if (!client)
      return Promise.reject(
        new Error('LaunchDarklyService service is not present')
      );

    await client.identify({
      ...client.getContext(),
      ...getUserLDContext(uuid, email),
    });
  };

  const reset = async () => {
    if (!client)
      return Promise.reject(
        new Error('LaunchDarklyService service is not present')
      );

    return client.identify({
      ...client.getContext(),
      ...getAnonymousUserLDContext(),
    });
  };

  return {
    waitForInitialization,
    isInitialized,
    track,
    identify,
    reset,
  };
};
