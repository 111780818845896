import { FAQ, IQuestion } from '@axo/deprecated/util/ui-components';
import { useTranslation } from '@axo/mypage/util/translation';
import { Product } from '@axo/shared/data-access/types';

const VITE_APP_FAQ_IDS = import.meta.env.VITE_APP_FAQ_IDS?.split(',');

type IFAQElement = {
  product: Product;
};

export const FAQElement = ({ product }: IFAQElement) => {
  const { t } = useTranslation();

  /**
   * "open: false" prop is a workaround to close all FAQ items on product change.
   * <details> is ignoring component re-render and keep open state.
   */
  const elements: IQuestion[] =
    product === Product.UnsecuredLoan
      ? [
          {
            _id: 'faq-question-1',
            question: t('UL FAQ Question 1') as string,
            answer: t('UL FAQ Answer 1') as string,
            open: false,
          },
          {
            _id: 'faq-question-2',
            question: t('UL FAQ Question 2') as string,
            answer: t('UL FAQ Answer 2') as string,
            open: false,
          },
          {
            _id: 'faq-question-3',
            question: t('UL FAQ Question 3') as string,
            answer: t('UL FAQ Answer 3') as string,
            open: false,
          },
          {
            _id: 'faq-question-4',
            question: t('UL FAQ Question 4') as string,
            answer: t('UL FAQ Answer 4') as string,
            open: false,
          },
          {
            _id: 'faq-question-5',
            question: t('UL FAQ Question 5') as string,
            answer: t('UL FAQ Answer 5') as string,
            open: false,
          },
          {
            _id: 'faq-question-6',
            question: t('UL FAQ Question 6') as string,
            answer: t('UL FAQ Answer 6') as string,
            open: false,
          },
          {
            _id: 'faq-question-7',
            question: t('UL FAQ Question 7') as string,
            answer: t('UL FAQ Answer 7') as string,
            open: false,
          },
          {
            _id: 'faq-question-8',
            question: t('UL FAQ Question 8') as string,
            answer: t('UL FAQ Answer 8') as string,
            open: false,
          },
          {
            _id: 'faq-question-9',
            question: t('UL FAQ Question 9') as string,
            answer: t('UL FAQ Answer 9') as string,
            open: false,
          },
        ]
      : [
          {
            _id: 'faq-question-1',
            question: t('CC FAQ Question 1') as string,
            answer: t('CC FAQ Answer 1') as string,
          },
          {
            _id: 'faq-question-2',
            question: t('CC FAQ Question 2') as string,
            answer: t('CC FAQ Answer 2') as string,
          },
          {
            _id: 'faq-question-3',
            question: t('CC FAQ Question 3') as string,
            answer: t('CC FAQ Answer 3') as string,
          },
          {
            _id: 'faq-question-4',
            question: t('CC FAQ Question 4') as string,
            answer: t('CC FAQ Answer 4') as string,
          },
          {
            _id: 'faq-question-5',
            question: t('CC FAQ Question 5') as string,
            answer: t('CC FAQ Answer 5') as string,
          },
          {
            _id: 'faq-question-6',
            question: t('CC FAQ Question 6') as string,
            answer: t('CC FAQ Answer 6') as string,
          },
          {
            _id: 'faq-question-7',
            question: t('CC FAQ Question 7') as string,
            answer: t('CC FAQ Answer 7') as string,
          },
        ];

  const filteredFAQ = (elements: IQuestion[]) =>
    elements.filter(
      (element) =>
        !VITE_APP_FAQ_IDS || VITE_APP_FAQ_IDS.indexOf(element._id) > -1
    );

  return (
    <FAQ
      _id={'faq-element-id'}
      faq={filteredFAQ(elements)}
      heading={t('Frequently asked questions') as string}
    />
  );
};
