import { KnownResourceType } from '@axo/shared/data-access/event-log';
import { event_log, loan_quote } from '@axo/shared/data-access/types';

export function breadcrumbs(quote: loan_quote.LoanQuote): event_log.Resource[] {
  return [
    {
      Type: KnownResourceType.LoanQuote,
      ID: quote.ID,
    },
    {
      Type: KnownResourceType.LoanRequest,
      ID: quote.LoanRequestID,
    },
    {
      Type: KnownResourceType.Lender,
      ID: quote.LenderID,
    },
  ];
}
