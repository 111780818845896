import { removeToken } from '@axo/mypage/util';
import { useCrossDomainAuthStorage } from '@axo/shared/auth/crossdomain/useCrossDomainAuthStorage';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import { useAnalytics } from '@axo/shared/services/analytics';
import { useCallback, useContext, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { RoleName } from '../../../../shared/data-access/types/src/permissions';

type UseAuthProvider = {
  logout: () => void;
  isLoggedIn: boolean;
  isLoggedInAsVerified: boolean;
  isLoggedInAsUnverified: boolean;
};

export const useAuthProvider = (): UseAuthProvider => {
  const { clear: clearCrossDomainAuth } = useCrossDomainAuthStorage();
  const { state, dispatch } = useContext(DataAccessContext);
  const { dispatch: appDispatch } = useContext(LoanApplicationContext);
  const { reset } = useAnalytics();

  const client = useQueryClient();

  const logout = useCallback(async () => {
    removeToken();
    clearCrossDomainAuth();

    // client.resetQueries();
    // client.clear();

    dispatch({ type: 'Unset auth', scope: { parentType: 'user' } });
    appDispatch({
      type: 'Unset application',
      scope: { parentType: 'application' },
    });

    reset();
  }, [dispatch, appDispatch, clearCrossDomainAuth, client, reset]);

  const isLoggedIn = useMemo(() => {
    return state.user.token !== '';
  }, [state.user.token]);

  const verifiedRoles = [RoleName.CustomerVerifiedEmail, RoleName.Person];

  const isLoggedInAsVerified = useMemo(() => {
    return (
      isLoggedIn &&
      state.user.roles.some((role) =>
        verifiedRoles.includes(role.Name as RoleName)
      )
    );
  }, [isLoggedIn, state.user.roles]);

  const isLoggedInAsUnverified = useMemo(() => {
    return (
      isLoggedIn &&
      state.user.roles.some((role) => role.Name === RoleName.Customer) &&
      !isLoggedInAsVerified
    );
  }, [isLoggedIn, isLoggedInAsVerified, state.user.roles]);

  return {
    logout,
    isLoggedIn,
    isLoggedInAsVerified,
    isLoggedInAsUnverified,
  };
};
