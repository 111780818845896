import { useCreateTicket } from '@axo/shared/data-access/hooks';
import { DataAccessContext, useAPI } from '@axo/shared/data-access/provider';
import { useCallback, useContext, useEffect, useRef, useReducer } from 'react';
import { IPublishFunction } from './types';
import { CustomWSStatus, WSReadyState, Status } from './status';

type WebSocketState = {
  status: Status;
  attemptsCount: number;
};

type WebSocketAction =
  | { type: 'INITIATED' }
  | { type: 'OPENED' }
  | { type: 'CLOSED' }
  | { type: 'ERRORED' }
  | { type: 'ATTEMPTED' }
  | { type: 'RESETTED' };

const MAX_ATTEMPS = 3;

const initialState = {
  status: CustomWSStatus.None,
  attemptsCount: 0,
};

function websocketReducer(state: WebSocketState, action: WebSocketAction) {
  switch (action.type) {
    case 'INITIATED':
      return { ...state, status: WSReadyState.Connecting };
    case 'OPENED':
      return { ...state, status: WSReadyState.Open, attemptsCount: 0 };
    case 'CLOSED':
      return { ...state, status: WSReadyState.Closed };
    case 'ERRORED':
      return { ...state, status: CustomWSStatus.Error };
    case 'ATTEMPTED':
      return {
        ...state,
        status: WSReadyState.Connecting,
        attemptsCount: state.attemptsCount + 1,
      };
    case 'RESETTED':
      return {
        ...state,
        status: CustomWSStatus.None,
        attemptsCount: 0,
      };
    default:
      throw new Error(`Unhandled action type`);
  }
}

export function useWebSocket(publish: IPublishFunction) {
  const { mutateAsync: createTicket } = useCreateTicket();
  const {
    state: {
      user: { token, tokenExpiration },
    },
  } = useContext(DataAccessContext);
  const {
    url: { ws: wsURL },
  } = useAPI();

  const [state, dispatch] = useReducer(websocketReducer, initialState);
  const websocket = useRef<WebSocket | null>(null);

  const createWSConnection = useCallback(
    async (wsURL: string | undefined) => {
      dispatch({ type: 'INITIATED' });

      try {
        const ticket = await createTicket();
        if (!ticket || !wsURL) throw new Error('Missing ticket or WS URL');

        const url = new URL(wsURL);
        url.searchParams.set('ticket', ticket.ID);
        const ws = new WebSocket(url.toString());

        websocket.current = ws;

        ws.onopen = () => {
          dispatch({ type: 'OPENED' });
        };

        ws.onmessage = (event) => {
          const messageData = JSON.parse(event.data);
          publish({
            source: messageData.source,
            code: messageData.code,
            latestMessage: messageData,
            status: ws.readyState,
          });
        };
        ws.onclose = () => {
          dispatch({ type: 'CLOSED' });
        };
        ws.onerror = () => {
          dispatch({ type: 'ERRORED' });
        };
      } catch (error) {
        dispatch({ type: 'ERRORED' });
      }
    },
    [createTicket, publish]
  );

  function manualRetry() {
    if ([WSReadyState.Closed, CustomWSStatus.Error].includes(state.status))
      dispatch({ type: 'RESETTED' });
  }

  useEffect(() => {
    if (!tokenExpiration) return;

    const authTimeoutMs = tokenExpiration * 1000 - Date.now();
    if (authTimeoutMs > 0) {
      const authTimeout = window.setTimeout(() => {
        dispatch({ type: 'CLOSED' });
        websocket.current?.close();
      }, authTimeoutMs);
      return () => clearTimeout(authTimeout);
    }
  }, [tokenExpiration]);

  useEffect(() => {
    if (!token || (tokenExpiration && tokenExpiration * 1000 <= Date.now())) {
      return;
    }
    if (
      websocket.current &&
      [
        WSReadyState.Connecting,
        WSReadyState.Open,
        WSReadyState.Closing,
      ].includes(state.status as WSReadyState)
    ) {
      return;
    }

    let attemptTimeout: number | null = null;
    if (state.attemptsCount <= MAX_ATTEMPS) {
      dispatch({ type: 'ATTEMPTED' });
      attemptTimeout = window.setTimeout(() => {
        createWSConnection(wsURL);
      }, calculateAttemptDelay(state.attemptsCount));
    } else return;
    const ws = websocket.current;

    function cleanUp() {
      if (ws) {
        ws.onopen = null;
        ws.onmessage = null;
        ws.onclose = null;
        ws.onerror = null;

        ws.close();
      }
      websocket.current = null;

      if (attemptTimeout) {
        clearTimeout(attemptTimeout);
        attemptTimeout = null;
      }
    }

    return cleanUp;
  }, [
    token,
    tokenExpiration,
    wsURL,
    state.status,
    createWSConnection,
    state.attemptsCount,
    state,
  ]);

  const calculateAttemptDelay = (attemptsCount: number) => {
    return attemptsCount === 0
      ? attemptsCount
      : Math.min(1000 * 2 ** attemptsCount, 30000); // Cap the retry delay at 30 seconds
  };

  return { status: state.status, manualRetry };
}
