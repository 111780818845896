import { createContext, Dispatch, ReactNode, useReducer } from 'react';
import { TProgressBarWithDescriptionProps } from '@axo/ui-feature/components/ProgressBarWithDescription/ProgressBarWithDescription';
import {
  CustomerEventType,
  HumanReadableID,
} from '@axo/shared/data-access/types';

type ProgressData = Exclude<TProgressBarWithDescriptionProps, 'className'>;
type EventData = {
  humanReadableID: HumanReadableID;
  name: CustomerEventType;
};
type OffersData = {
  total: number;
  completed: number;
};
type State = ProgressData & {
  applicationEvent?: EventData;
  offers: OffersData;
};
type Action =
  | { type: 'Reset progressbar' }
  | { type: 'Set progress'; payload: ProgressData }
  | { type: 'Set event'; payload: EventData }
  | { type: 'Set offers'; payload: OffersData };

export const initialProgressState: State = {
  title: '',
  description: '',
  state: 'neutral',
  progress: 0,
  offers: {
    total: 0,
    completed: 0,
  },
};

export const ApplicationProgressContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: initialProgressState,
  dispatch: () => undefined,
});

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'Reset progressbar':
      return { ...state, progress: initialProgressState.progress };
    case 'Set progress':
      return { ...state, ...action.payload };
    case 'Set event':
      return { ...state, applicationEvent: action.payload };
    case 'Set offers':
      return { ...state, offers: action.payload };
    default:
      return state;
  }
};

export const ApplicationProgressProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(reducer, initialProgressState);
  return (
    <ApplicationProgressContext.Provider value={{ state, dispatch }}>
      {children}
    </ApplicationProgressContext.Provider>
  );
};
