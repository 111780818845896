import { loan_quote, loan_quote_presentation } from '../index';

export enum Status {
  Pending = 'pending',
  Accepted = 'accepted',
  AlreadyAccepted = 'already-accepted',
  Failed = 'failed',
}

export interface LoanQuoteAccept {
  ID: string;
  CreatedAt: string;
  UpdatedAt: string;
  LoanQuoteID: string;
  CustomerID?: string;
  LoanApplicationID: string;
  PresentationHash: string;
  AcceptableQuoteIndex?: number;
  NumAcceptableQuotes?: number;
  NumPreviouslyAcceptedQuotes?: number;
  AcceptQuoteInput: loan_quote.AcceptQuoteInput;
  Status: Status;
}

export interface Create {
  LoanQuoteID: string;
  CustomerID?: string;
  PersonID?: string | null;
  LoanApplicationID: string;
  PresentationHash?: string;
  AcceptableQuoteIndex?: number;
  NumAcceptableQuotes?: number;
  NumPreviouslyAcceptedQuotes?: number;
  AcceptQuoteInput: loan_quote.AcceptQuoteInput;
}

export type TAcceptOfferModal = {
  offer: loan_quote_presentation.PresentedLoanQuote;
  closeModal: () => void;
  marketCountry: string;
  onAcceptInsurance?: () => void;
  onDeclineInsurance?: () => void;
};
